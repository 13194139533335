import { useState, useEffect } from 'react';

const useInView = ({
	ref,
	rootMargin = window.innerHeight * 0.75,
	checkImmediately = false,
	checkOnce = true
}) => {
	const [inView, setInView] = useState(false);

	useEffect(() => {
		if (inView && checkOnce) return;

		const handleScroll = () => {
			const rect = ref.current?.getBoundingClientRect();

			if (rect && rect.top && rect.top < window.innerHeight + rootMargin) {
				setInView(true);
			} else {
				if (!checkOnce) setInView(false);
			}
		};

		if (checkImmediately) handleScroll();

		window.addEventListener('scroll', handleScroll);

		return () => window.removeEventListener('scroll', handleScroll);
	}, [inView, rootMargin, checkOnce, checkImmediately, ref]);

	return { inView };
};

export default useInView;
