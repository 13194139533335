import React from 'react';
import Columns from '../Columns';
import Column from '../Column';
import styles from './index.module.scss';
import Connection from '../Connection';
import { socials } from 'src/variables';

const Navigation = () => (
	<div className={styles.root}>
		<div>
			<Columns isFirst>
				<Column {...socials} />
				<Column
					title='Почитать'
					links={[
						{
							title: 'Newsletters',
							url: 'https://novayagazeta.eu/newsletters',
							external: true
						}
					]}
				/>
				<Connection />
			</Columns>
		</div>
	</div>
);

export default Navigation;
