import React from 'react';
import cx from 'classnames';
import styles from './index.module.scss';

const Container = ({ className, center, block, row, ...other }) => (
	<div
		className={cx(styles.root, {
			[className]: Boolean(className),
			[styles.center]: center,
			[styles.block]: block,
			[styles.row]: row
		})}
		{...other}
	/>
);

export default Container;
