import React from 'react';
import styles from './index.module.scss';

const Logo = () => (
	<a href='/' target='_self' className={styles.root} title='Новая газета Европа'>
		<figure className={styles.figure}>
			<img src='/ic_mainLogo.svg' alt='logo' className={styles.mobileSvg} />
			<img src='/logo-white-ru.svg' alt='logo' className={styles.svg} />
		</figure>
	</a>
);

export default Logo;
