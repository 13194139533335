export const headerNavigation = [
	{ title: 'Новости', url: 'https://novayagazeta.eu/news', icon: '/ic_speaker_24dp.svg' },
	{ title: 'Истории', url: 'https://novayagazeta.eu/stories', icon: '/ic_newspaper_24dp.svg' },
	{ title: 'Мнения', url: 'https://novayagazeta.eu/views', icon: '/ic_user_24dp.svg' },
	{ title: 'Видео', url: 'https://novayagazeta.eu/video', icon: '/ic_video_24dp.svg' }
];

export const socials = {
    title: 'Соцсети',
    links: [
        {
            icon: 'Twitter',
            external: true,
            url: 'https://twitter.com/novayagazeta_eu',
            title: 'Twitter'
        },
        {
            icon: 'Twitter',
            external: true,
            url: 'https://twitter.com/novayagazeta_en',
            title: 'Twitter',
            isNew: true
        },
        {
            icon: 'Youtube',
            external: true,
            url: 'https://www.youtube.com/channel/UCO1lJlI3gjuOMrKppvPlDww',
            title: 'YouTube'
        },
        {
            icon: 'Instagram',
            external: true,
            url: 'https://www.instagram.com/novayagazeta_eu',
            title: 'Instagram'
        },
        {
            icon: 'Instagram',
            external: true,
            url: 'https://www.instagram.com/novayagazeta_eu.eng',
            title: 'Instagram',
            isNew: true
        },
        { icon: 'Telegram', external: true, url: 'https://t.me/novaya_europe', title: 'Telegram' },
        {
            icon: 'Facebook',
            external: true,
            url: 'https://www.facebook.com/Novaya-Gazeta-Europe-107512075260896',
            title: 'Facebook'
        },
        {
            icon: 'TikTok',
            external: true,
            url: 'https://www.tiktok.com/@novayagazeta_eu?_t=8WdrffkgqOo&_r=1',
            title: 'TikTok'
        }
    ],
    isSocial: true
};