import React from 'react';
import { Route, Routes } from 'react-router-dom';
import VPNovaya from './pages/VPNovaya';
import Page404 from './pages/Page404';

const MainRoutes = () => (
	<Routes>
		<Route path='/' element={<VPNovaya />} />
		<Route path='*' element={<Page404 />} />
	</Routes>
);

export default MainRoutes;
