import React from 'react';
import cx from 'classnames';
import styles from './index.module.scss';

const Columns = ({ children, isFirst, showAll }) => (
	<div className={cx(styles.root, { [styles.second]: !isFirst, [styles.showMin]: !showAll })}>
		{children}
	</div>
);

export default Columns;
