import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import useInView from 'src/hooks/useInView';

const RenderWhenInView = ({ children, rootMargin, checkImmediately, placeholderHeight = '0px' }) => {
    const ref = useRef();

    const { inView } = useInView({ ref, checkImmediately, rootMargin });

    if (inView) return children;

    return <div style={{ height: placeholderHeight }} ref={ref} />;
};

RenderWhenInView.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
    rootMargin: PropTypes.number,
    checkImmediately: PropTypes.bool,
    placeholderHeight: PropTypes.string
};

export default RenderWhenInView;
