import React, { lazy } from 'react';
import MainLayout from '../layouts/MainLayout';

const VPNovayaContent = lazy(() => import('../content/VPNovaya'));

const VPNovaya = () => (
	<MainLayout>
		<VPNovayaContent />
	</MainLayout>
);

export default VPNovaya;
