import React, { Suspense } from 'react';
import ErrorBoundary from '../../components/ErrorBoundary';
import RenderWhenInView from '../../components/RenderWhenInView';
import Footer from './Footer';
import Registered from './Registered';
import Menu from './Menu';
import styles from './index.module.scss';

const MainLayout = ({ children }) => (
	<>
		<Menu />
		<div className={styles.container} id='mainContainer'>
			<ErrorBoundary>
				<Suspense fallback={<div style={{ minHeight: '100vh' }} />}>{children}</Suspense>
			</ErrorBoundary>
			<>
				<RenderWhenInView>
					<ErrorBoundary>
						<Footer />
					</ErrorBoundary>
				</RenderWhenInView>
				<Registered />
			</>
		</div>
	</>
);

export default MainLayout;
