import React, { useState } from 'react';
import cx from 'classnames';
import styles from './index.module.scss';

const MenuButton = ({ setIsSideMenuOpen, isSideMenuOpen }) => {
	const [isFirstClick, setIsFirstClick] = useState(true);

	const handleClick = () => {
		if (isFirstClick) setIsFirstClick(false);
		setIsSideMenuOpen(!isSideMenuOpen);
	};

	return (
		<button className={styles.root} onClick={handleClick}>
			<img
				src='/ic_search_and_menu_32dp.svg'
				alt='menu'
				className={cx(styles.icon, {
					[styles.hide]: isSideMenuOpen,
					[styles.show]: !isSideMenuOpen && !isFirstClick
				})}
			/>
			<img
				src='/ic_close_white_32dp.svg'
				alt='close'
				className={cx(styles.icon, {
					[styles.hide]: !isSideMenuOpen,
					[styles.show]: isSideMenuOpen
				})}
			/>
		</button>
	);
};

export default MenuButton;
