import React, { useEffect, useState } from 'react';
import { headerNavigation } from 'src/variables';
import useWindowSize from 'src/hooks/useWindowSize';
import ScrollMenu from '../ScrollMenu';
import Container from 'src/components/Container';
import Logo from './Logo';
import DonateButton from 'src/components/Buttons/DonateButton';
import MenuButton from './MenuButton';
import SideMenu from './SideMenu';
import styles from './index.module.scss';

const MenuEurope = () => {
	const { width } = useWindowSize();
	const isMobile = width < 1024;
	const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);

	useEffect(() => {
		document.body.style.overflow = isSideMenuOpen ? 'hidden' : '';

		return () => {
			document.body.style.overflow = '';
		};
	}, [isSideMenuOpen]);

	return (
		<>
			<ScrollMenu className={styles.header}>
				<Container className={styles.container}>
					<Logo />
					<ul className={styles.navigation}>
						{headerNavigation.map(({ title, url }) => (
							<li key={url}>
								<a
									href={url}
									target='_blank'
									rel='noopener noreferrer'
									title={title}
									className={styles.link}
								>
									<span>{title}</span>
								</a>
							</li>
						))}
					</ul>
					<div className={styles.btns}>
						<DonateButton text='Стать другом' className={styles.donateButton} />
						<MenuButton isSideMenuOpen={isSideMenuOpen} setIsSideMenuOpen={setIsSideMenuOpen} />
					</div>
				</Container>
			</ScrollMenu>
			<SideMenu isSideMenuOpen={isSideMenuOpen && isMobile} setIsSideMenuOpen={setIsSideMenuOpen} />
		</>
	);
};

export default MenuEurope;
