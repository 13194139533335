import React from 'react';
import cx from 'classnames';
import styles from './index.module.scss';

const DonateButton = ({ text, className, onClick = () => {} }) => (
	<a
		href='https://novayagazeta.eu/donate?utm_source=vpnovaya&utm_medium=header&utm_campaign=web'
		className={cx(styles.root, className)}
		target='_blank'
		rel='noreferrer noopener'
		onClick={onClick}
	>
		<span>{text}</span>
	</a>
);

export default DonateButton;
