import GlobalManager from './components/GlobalManager';
import MainRoutes from './MainRoutes';
import './styles/index.scss';
import styles from './styles/index.module.scss';

function App() {
	return (
		<main className={styles.root} id='mainNode'>
			<GlobalManager />
			<MainRoutes />
		</main>
	);
}

export default App;
