import React from 'react';
import cx from 'classnames';
import styles from './index.module.scss';
import ClientPortal from '../ClientPortal';

const Backdrop = ({ isOpen, onClose, children, ...rest }) => (
	<ClientPortal>
		<div className={cx(styles.backdrop, { [styles.open]: isOpen })} onClick={onClose} {...rest}>
			<div onClick={(e) => e.stopPropagation()}>{children}</div>
		</div>
	</ClientPortal>
);

export default Backdrop;
