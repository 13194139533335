import React from 'react';
import cx from 'classnames';
import styles from './index.module.scss';
import Title from '../Title';

const Connection = () => (
	<div className={cx(styles.root, styles.wide)}>
		<Title title='Контакты' />
		<ul className={styles.listContatcs}>
			<li className={styles.item}>
				<div className={styles.textRoot}>
					<p className={cx(styles.text, styles.marginTop)}>Общие вопросы:</p>
					<a href='mailto:contact@novayagazeta.eu'>contact@novayagazeta.eu</a>
					<p className={cx(styles.text, styles.marginTop)}>Отдел рекламы:</p>
					<a href='mailto:ads@novayagazeta.eu'>ads@novayagazeta.eu</a>
				</div>
			</li>
		</ul>
	</div>
);

export default Connection;
