import React from 'react';
import styles from './index.module.scss';

const Registered = () => (
	<div className={styles.root}>
		<small className={styles.copyright}>
			Главный редактор «Новой газеты Европа» — Кирилл Мартынов.{' '}
			<a
				href='https://novayagazeta.eu/terms'
				target='_blank'
				rel='noopener noreferrer'
				title='Пользовательское соглашение'
			>
				Пользовательское соглашение
			</a>
			.{' '}
			<a
				href='https://novayagazeta.eu/privacy'
				target='_blank'
				rel='noopener noreferrer'
				title='Политика конфиденциальности'
			>
				Политика конфиденциальности
			</a>
			.
		</small>
	</div>
);
export default Registered;
