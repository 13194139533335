import React from 'react';
import cx from 'classnames';
import styles from './index.module.scss';

const SocialsBlock = ({ className, size = 'small' }) => {
	const socials = [
		{ icon: 'telegram', url: 'https://t.me/novaya_europe', title: 'Telegram' },
		{
			icon: 'youtube',
			url: 'https://www.youtube.com/channel/UCO1lJlI3gjuOMrKppvPlDww',
			title: 'YouTube'
		},
		{
			icon: 'twitter',
			url: 'https://twitter.com/novayagazeta_eu',
			title: 'Twitter'
		},
		{
			icon: 'instagram',
			url: 'https://www.instagram.com/novayagazeta_eu',
			title: 'Instagram'
		},
		{
			icon: 'facebook',
			url: 'https://www.facebook.com/Novaya-Gazeta-Europe-107512075260896',
			title: 'Facebook'
		},
		{
			icon: 'tiktok',
			url: 'https://www.tiktok.com/@novayagazeta_eu?_t=8WdrffkgqOo&_r=1',
			title: 'TikTok'
		},
		{
			icon: 'vpnovaya',
			url: 'https://novayagazeta.eu/vpnovaya',
			title: 'VPNovaya'
		}
	];

	return (
		<ul className={cx(styles.socials, className, [styles[size]])}>
			{socials.map(({ icon, url, title }) => {
				const isVPNovaya = icon === 'vpnovaya';
				return (
					<li key={url} style={isVPNovaya ? { opacity: 1 } : {}}>
						<a href={url} target='_blank' rel='noopener noreferrer' title={title}>
							{isVPNovaya ? (
								<img
									src='/ic_vpnovayaLogo_red_26dp.svg'
									alt='vpnovaya'
									className={styles.VPNovayaIcon}
									width={26}
									height={26}
								/>
							) : (
								<img src={`/ic_${icon}_36dp.svg`} alt='icon' />
							)}
						</a>
					</li>
				);
			})}
		</ul>
	);
};

export default SocialsBlock;
