import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

const ClientPortal = ({ children, selector = 'body' }) => {
	const ref = useRef(null);
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		ref.current = document.querySelector(selector);
		setMounted(true);
	}, [selector]);

	return mounted ? createPortal(children, ref.current) : null;
};

export default ClientPortal;
