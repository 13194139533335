import React, { useRef } from 'react';
import useInView from 'src/hooks/useInView';
import Navigation from './Navigation';
import styles from './index.module.scss';

const Footer = () => {
	const ref = useRef(null);
	const { inView } = useInView({ ref });

	return (
		<div ref={ref} className='Footer'>
			{inView && (
				<footer className={styles.root}>
					<div className={styles.backy}>
						<Navigation />
					</div>
				</footer>
			)}
		</div>
	);
};

export default Footer;
