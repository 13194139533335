import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import Title from '../Title';
import styles from './index.module.scss';

const Column = ({ title, links, notShowOnMobile, notShowOnDesktop, children }) => {
	let type = '';
	if (links[0]?.data) {
		type = links && links[0].data;
	}
	const isWide = type === 'connection';
	return (
		<div
			className={cx(styles.root, {
				[styles.wide]: isWide,
				[styles.mobileShow]: notShowOnMobile,
				[styles.notShowOnDesktop]: notShowOnDesktop
			})}
		>
			<Title title={title} />
			<ul className={styles.list}>
				{links
					? links.map(({ url, title, icon, external, isNew, text }, i) => (
							<li className={styles.item} key={i}>
								{icon || external ? (
									<a
										href={url || '/'}
										rel='noreferrer'
										className={isNew ? styles.newitem : styles.link}
										target='_blank'
									>
										{title}
									</a>
								) : (
									<Link to={url || '/'} activeclassname={styles.active} className={styles.link}>
										{title}
									</Link>
								)}
							</li>
					  ))
					: null}
				{children}
			</ul>
		</div>
	);
};

export default Column;
