import React from 'react';
import cx from 'classnames';
import styles from './index.module.scss';
import { headerNavigation } from 'src/variables';
import Backdrop from 'src/components/Backdrop';
import DonateButton from 'src/components/Buttons/DonateButton';
import SocialsBlock from 'src/components/SocialsBlock';

const SideMenu = ({ isSideMenuOpen, setIsSideMenuOpen }) => (
	<Backdrop
		onClose={() => setIsSideMenuOpen(false)}
		isOpen={isSideMenuOpen}
		style={{ zIndex: 1298 }}
	>
		<div onClick={() => setIsSideMenuOpen(false)}>
			<div
				className={cx(styles.root, {
					[styles.isVisible]: isSideMenuOpen
				})}
				onClick={(e) => e.stopPropagation()}
			>
				<div className={styles.container}>
					<ul className={styles.navigation}>
						{headerNavigation.map(({ title, url, icon }) => (
							<li
								key={url}
								className={styles.navigationItem}
								onClick={() => {
									setIsSideMenuOpen(false);
								}}
							>
								<a href={url} title={title} target='_blank' rel='noopener noreferrer'>
									<img src={icon} alt='icon' />
									<span>{title}</span>
								</a>
							</li>
						))}
					</ul>
					<DonateButton text='Стать другом' className={styles.donateButton} />
					<SocialsBlock className={styles.socials} size='big' />
				</div>
			</div>
		</div>
	</Backdrop>
);

export default SideMenu;
